.DateRangePicker {
    display: block;
    
}
.DateRangePicker_picker {
    z-index: 999;
}
.DateRangePickerInput__withBorder {
    border-color: rgb(186,191,196) !important;
    border-radius: 4px;
    overflow: hidden;
    display: block;
    height: 100%;
}

.DateInput_input {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 16px;
    height: 34px;
}